let costButton = Array.prototype.slice.call(document.querySelectorAll(".cost>button"))
let nextButton = document.querySelector(".button-next")
let costCase = document.querySelector(".cost")
let costCaseWpay = document.querySelector(".cost-wpay")
let displayCurrency = document.querySelector(".display-currency")

let urlpay = "https://www.ipay.ua/ua/charger?bill_id=3414&acc=123456&invoice=&"

//======================================================================

let wpay25 = `https://secure.wayforpay.com/button/b65cf08825560`
let wpay100 = `https://secure.wayforpay.com/button/bbc958d2672e7`
let wpay500 = `https://secure.wayforpay.com/button/b33dab61cc82d`
let wpayFree = `https://secure.wayforpay.com/button/b7a52b942cb68`

let wLogo = `https://wayforpay.com/assets/88b3e38b/images/WayForPay_logo_black.png`


class ButtonWpay {
    constructor(cost){
        this.cost = cost
    }

    render(){
        const construct = `<button class="donate_button"  id="${this.cost}"> ${this.cost} ${typeof this.cost != "string" ? "ГРН":"<br>"} </button>`
        costCaseWpay.innerHTML += construct
    }
}

let wPayButton25 = new ButtonWpay(25)
let wPayButton100 = new ButtonWpay(100)
let wPayButton500 = new ButtonWpay(500)
let wPayButtonFree = new ButtonWpay("СВОЯ СУМА")

// wPayButton25.render()
// wPayButton100.render()
// wPayButton500.render()
// wPayButtonFree.render()

class EventButtonWpay {
    constructor(cost0,cost1,cost2,cost3){
        this.cost0 = cost0
        this.cost1 = cost1
        this.cost2 = cost2
        this.cost3 = cost3
    }

    event(){
        Array.prototype.slice.call(document.querySelectorAll(".donate_button")).map((el)=>{
            el.addEventListener("click", (el)=>{
                
                switch (el.target.id){
                    case "25":
                        location.href = wpay25
                        break;
                    case "100":
                        location.href = wpay100
                        break;
                    case "500":
                        location.href = wpay500
                        break;
                    default:
                        location.href = wpayFree
                        break;
                    
                }
            })
        })
    }
}

// let controlWpay = new EventButtonWpay(wpay25,wpay100,wpay500,wpayFree)
// controlWpay.event()

//=============================================================

class ButtonCost {
    constructor(costButton,nextButton,display){
        this.cost = 100
        this.arrayCost = costButton
        this.nextButton = nextButton
        this.display = display
    }

    event(){
        this.display.placeholder = `вкажіть суму`
        this.arrayCost.map((el)=>{
            el.addEventListener("click",()=>{
                this.cost = el.id
                this.arrayCost.map((el)=>{
                    el.classList.remove("cost_button__act")
                    this.display.value = `${this.cost}`
                })
                document.getElementById(`${this.cost}`).classList.add("cost_button__act")
            })  
        })

        let urlPAy = document.location.search
        let params = new URLSearchParams(urlPAy)
        let metaInfo = {merchantPointId:"1",merchantId:"1", posterId:"1", desc:"1"}
        

        for(let pair of params.entries()) {
            if(pair[0]){
                metaInfo[pair[0]] = pair[1];
            }
        }

        displayCurrency.value = this.cost
        document.addEventListener("keydown",()=>{
            this.arrayCost.map((el)=>{el.classList.remove("cost_button__act")})
            displayCurrency.classList.remove("display-currency__error")
        })

        displayCurrency.addEventListener("click",()=>{
            displayCurrency.value = ""
            this.arrayCost.map((el)=>{el.classList.remove("cost_button__act")})
        })

        this.nextButton.addEventListener("click", ()=>{
            this.cost = displayCurrency.value
            if(this.cost >= 15 && this.cost <=100000){
                let ipay = `https://www.ipay.ua/ua/charger?bill_id=3414&merchantPointId=${metaInfo["merchantPointId"]}&merchantId=${metaInfo["merchantId"]}&posterId=${metaInfo["posterId"]}&invoice=${this.cost}&desc=${metaInfo["desc"]}`
                location.href = ipay

                console.log(ipay ,metaInfo["desc"] )
            }else if(this.cost <= 14 || this.cost === 0){
                displayCurrency.value = ""
                displayCurrency.placeholder = "мінімальна сума 15грн"
                displayCurrency.classList.add("display-currency__error")
            }else{
                displayCurrency.value = ""
                displayCurrency.placeholder = "вкажіть суму"
                displayCurrency.classList.add("display-currency__error")
            }
        })
    }    
}



let controlModail = new ButtonCost(costButton,nextButton,displayCurrency)
controlModail.event()


